/**
 * Create the store with dynamic reducers
 */

import { configureStore, StoreEnhancer } from "@reduxjs/toolkit";
import { createInjectorsEnhancer } from "redux-injectors";
import createSagaMiddleware from "redux-saga";

import { createReducer } from "./reducers";

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  const store = configureStore({
    reducer: createReducer(),
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware(),
      ...middlewares,
    ],
    devTools:
      process.env.NODE_ENV !== "production"
        ? // Using large data in redux store causes performance issues in devtools
          // due to serialization. We can use these sanitizers for both actions and state
          // to avoid this.
          {
            actionSanitizer: (action: any) => {
              if (!action.payload?.data) return action;
              return {
                ...action,
                payload: {
                  ...action.payload,
                  data: "<<LONG_BLOB>>",
                },
              };
            },
            stateSanitizer: (state: any) => {
              if (!state) return state;
              return {
                ...state,
                ticket: {
                  ...state.ticket,
                  images: [],
                  documents: {},
                },
                app: {
                  ...state.app,
                  headerImageData: "<<LONG_BLOB>>",
                },
              };
            },
          }
        : false,
    enhancers,
  });

  return store;
}
